import { FileImage, FileMp4, FilePdf } from '@components';

interface Props {
  extension: 'pdf' | 'mp4' | 'png' | 'jpeg' | 'jpg' | 'gif' | string;
  url: string;
  previewUrl?: string;
  downloadFilename?: string;
}

export const FilePreview = ({ extension, url, previewUrl, downloadFilename }: Props) => {
  if (extension === 'pdf') {
    return (<FilePdf url={url} downloadFilename={downloadFilename} />);
  }
  if (extension === 'mp4') {
    return (<FileMp4 url={url} previewUrl={previewUrl} downloadFilename={downloadFilename} />);
  }
  if (['png', 'jpeg', 'jpg', 'gif'].includes(extension)) {
    return (<FileImage url={url} downloadFilename={downloadFilename} />);
  }

  return null;
};
