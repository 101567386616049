import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';

import { CreditRisk } from '@types';
import { InitialCard, InitialRow, ScoreWidget, SelectInput } from '@components';
import { creditStatusOptions } from '@pages/credit/CreditStatusField';
import { ExportRisk } from '@pages/credit-risk/export';

const statusOptions = creditStatusOptions
  .filter(o => ['moderation', 'bank_revision', 'approved', 'denied'].includes(o.id));

export const RiskProgressScore = ({ risk, readonly }: {risk: CreditRisk; readonly?: boolean}) => {
  const score = risk.riskScore || 0;
  const cls = useStyles();
  const [isReportOpened, setReportOpened] = useState(false);
  const openReport = risk ? () => setReportOpened(true) : undefined;

  const isDenied = risk?.credit?.isDenied;

  return (
    <>
      <InitialCard left="Score" autoHeight mediumPadding>
        <div className={cls.box}>
          <ScoreWidget
            value={score}
            percents={score < 80 ? 10 : 90}
            onClick={openReport}
          />
          <InitialRow title="Status" className={cls.statusRow}>
            <SelectInput
              label={false}
              source="creditStatus"
              choices={statusOptions}
              required
              disabled={readonly || isDenied}
            />
          </InitialRow>
        </div>
      </InitialCard>
      <ExportRisk
        creditId={risk.creditId}
        isOpened={isReportOpened}
        setOpened={setReportOpened}
      />
    </>
  );
};

const useStyles = makeStyles({
  box: {
  },
  statusRow: {
    marginLeft: '27%',
    marginRight: '33%',
    marginTop: 28,
  },
});
