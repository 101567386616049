import { Admin } from '@types';

const rbacDisabled = process.env.REACT_APP_RBAC_DISABLED === 'true';
export type Method = 'get' | 'post' | 'put' | 'delete';

export enum ROLES {
  CREDIT_ADMIN = 'Credit admin',
  CREDIT_STATUS_CONTROL = 'Credit Status Control',
  RISK_ADMIN = 'Risk',
  DRAFT_CREDIT = 'Draft Credit',
}

class Rbac {

  readonlyAccess(resourceName: string, adminArg?: Partial<Admin> | null): boolean {
    const admin = adminArg || this.getAdmin();
    if (rbacDisabled) return false;
    if (admin?.isSuperadmin) return false;

    const permissionKeys = (admin?.permissionKeys || null) as Record<string, boolean>;
    if (!permissionKeys) return false;

    const allKeys = [`${resourceName}|ALL`];
    const readonlyKeys = [`${resourceName}|READONLY`];

    if (resourceName.includes('/')) {
      const firstResource = resourceName.split('/')[0];
      allKeys.push(`${firstResource}|ALL`);
      readonlyKeys.push(`${firstResource}|READONLY`);
    }

    for (const key of allKeys) {
      if (permissionKeys[key]) return false;
    }
    for (const key of readonlyKeys) {
      if (permissionKeys[key]) return true;
    }

    return false;
  }

  checkAccess(admin: Partial<Admin> | null, resourceName: string, method: Method) {
    if (rbacDisabled || admin?.isSuperadmin) return true;
    const isReadAction = method === 'get' || method === 'put';

    const permissionKeys = (admin?.permissionKeys || null) as Record<string, boolean>;
    if (!permissionKeys) return false;

    const checkKeys: string[] = [];
    checkKeys.push(`${resourceName}|ALL`);
    checkKeys.push(`${resourceName}|${method}`);
    isReadAction && checkKeys.push(`${resourceName}|READONLY`);

    if (resourceName.includes('/')) {
      const firstResource = resourceName.split('/')[0];
      checkKeys.push(`${firstResource}|ALL`);
      checkKeys.push(`${firstResource}|${method}`);
      isReadAction && checkKeys.push(`${firstResource}|READONLY`);
    }

    for (const key of checkKeys) {
      if (permissionKeys[key]) return true;
    }

    return false;
  }

  canShowList(resourceName: string, adminArg?: any): boolean {
    const admin = adminArg || this.getAdmin();
    if (resourceName === 'risk' && this.hasRiskAdminReadonlyPerm()) return true;
    if (resourceName === 'credit-risk' && this.hasRiskManagerReadonlyPerm()) return true;

    return this.checkAccess(admin, resourceName, 'get');
  }

  canView(resourceName: string, adminArg?: any) {
    if (resourceName === 'risk') {
      if (this.hasRiskAdminReadonlyPerm()) return true;
    }
    if (resourceName === 'credit-risk') {
      if (this.hasRiskManagerReadonlyPerm()) return true;
    }
    const admin = adminArg || this.getAdmin();

    return this.checkAccess(admin, resourceName, 'get');
  }

  canEdit(resourceName: string, adminArg?: any) {
    if (resourceName === 'risk') {
      if (this.hasRiskAdminReadonlyPerm()) return true;
    }
    if (resourceName === 'credit-risk') {
      if (this.hasRiskManagerReadonlyPerm()) return true;
    }
    const admin = adminArg || this.getAdmin();

    return this.checkAccess(admin, resourceName, 'put');
  }

  canCreate(admin: Partial<Admin> | null, resourceName: string) {
    return this.checkAccess(admin, resourceName, 'post');
  }

  getAdmin(): Partial<Admin> | null {
    const localAdmin: string | null = localStorage.getItem('admin');

    return localAdmin ? JSON.parse(localAdmin) : null;
  }

  hasPermission(admin: Partial<Admin> | null, permission: string): boolean {
    const permissions = (admin?.permissionKeys || null) as Record<string, boolean>;

    return permissions && permissions[permission] ? true : false;
  }

  isSuperadmin(): boolean {
    const admin = this.getAdmin();

    return admin?.isSuperadmin ? true : false;
  }

  isInnovaworx(): boolean {
    const admin = this.getAdmin();
    let isInnovaworx = false;

    if (admin && (admin.roles || []).find(role => role.name === 'Innovaworx')) {
      isInnovaworx = true;
    }
    if (admin && admin.username === 'Innovaworx') {
      isInnovaworx = true;
    }

    return isInnovaworx;
  }

  hasRole(admin: Partial<Admin> | null, roleName: string): boolean {
    if (admin?.isSuperadmin) {
      return true;
    }
    const roles = admin?.roles || [];

    return roles.find(role => role.name === roleName) ? true : false;
  }

  hasPermissionOrSuperadmin(permissionKey: string): boolean {
    const admin = this.getAdmin();
    const permissionKeys = (admin?.permissionKeys || {}) as Record<string, boolean>;

    if (admin?.isSuperadmin) return true;

    console.log({ permissionKeys });

    return permissionKeys[permissionKey] ?? false;
  }

  hasFinanceBalancePerm(): boolean {
    return this.hasPermissionOrSuperadmin('finance-dashboard|ALL');
  }

  hasFinanceSettingsAll(): boolean {
    return this.hasPermissionOrSuperadmin('finance-settings|ALL');
  }

  hasFinanceSettingsReadonly(): boolean {
    return this.hasPermissionOrSuperadmin('finance-settings|READONLY');
  }

  hasRiskAdminRole(): boolean {
    return this.hasRole(this.getAdmin(), ROLES.RISK_ADMIN);
  }

  hasCreditAdminRole(): boolean {
    return this.hasRole(this.getAdmin(), ROLES.CREDIT_ADMIN);
  }

  hasDraftCredit(): boolean {
    return this.hasRole(this.getAdmin(), ROLES.DRAFT_CREDIT);
  }

  hasCreditStatusControl(): boolean {
    return this.hasRole(this.getAdmin(), ROLES.CREDIT_STATUS_CONTROL);
  }

  hasRiskAdminReadonlyPerm(): boolean {
    return this.hasPermission(this.getAdmin(), 'risk-admin-readonly|ALL');
  }

  hasRiskManagerReadonlyPerm(): boolean {
    return this.hasPermission(this.getAdmin(), 'risk-manager-readonly|ALL');
  }
}

export const rbac = new Rbac();
