import { useRbacList } from '@hooks';
import { DateField, Forbidden, Grid, GridList, TextInput } from '@components';
import React from 'react';
import { ListProps, TextField } from 'react-admin';

export const ManualsList = (props: ListProps) => {
  const rbacList = useRbacList();

  if (!rbacList) {
    return <Forbidden />;
  }

  const filters = [
    <TextInput source="q" key="q" variant="outlined" label="Search" alwaysOn />,
  ];

  return (
    <GridList
      {...props}
      filters={filters}
      sort={{ field: 'id', order: 'DESC' }}
    >
      <Grid
        rowClick="edit"
        defaultColumns={'username name email phone roles isEnabled isSuperadmin'.split(' ')}
      >
        <TextField source="title" />
        <TextField source="platform" />
        <TextField source="description" />
        <TextField source="team" />
        <DateField source="createdAt" label="Created" />
      </Grid>
    </GridList>
  );
};
