import { makeStyles } from '@mui/styles';
import { useEffect, useState } from 'react';

import { creditHttp, CreditPaymentCalculation } from '@network';
import { BlueButton, InfoTooltip } from '@components';
import { AntdDateControlledInput } from '@components/inputs/AntdDateControlledInput';

interface Props {
  creditId: number;
  paymentCustomDate: string | null;
  setPaymentCustomDate: (newDate: string | null) => void;
}

export const CreditPaymentAmount = ({
  creditId, paymentCustomDate, setPaymentCustomDate,
}: Props) => {
  const cls = useStyes();
  const [calculation, setCalculation] = useState<CreditPaymentCalculation>();

  useEffect(() => {
    (async () => {
      const nextCalculation = await creditHttp.paymentCalculation(creditId, {
        paymentCustomDate,
      });
      setCalculation(nextCalculation);
    })();
  }, [creditId, paymentCustomDate]);


  if (!calculation) {
    return null;
  }

  const onBtnCustom = () => paymentCustomDate === null && setPaymentCustomDate('');
  const onBtnFull = () => paymentCustomDate !== null && setPaymentCustomDate(null);

  return (
    <div className={cls.box}>
      <div className={cls.row}>
        <div className={cls.titleBold}>Payment amount ({calculation.paymentMoney})</div>
        <div className={cls.title}>=</div>
        <div className={cls.title}>Credit ({calculation.creditMoney})</div>
        <div className={cls.title}>+</div>
        <div className={cls.title}>Interests ({calculation.interestMoney})</div>
        <div className={cls.title}>+</div>
        <div className={cls.title}>Moratory Interests ({calculation.moratoryInterestMoney})</div>
      </div>

      <div className={cls.row}>
        <div className={cls.titleBold}>
          Moratory Interest ({calculation.moratoryInterestMoney})
        </div>
        <div className={cls.title}>=</div>
        <div className={cls.title}>
          <div className={cls.underline}>
            Moratory Interest Rate ({calculation.moratoryInterestRate})
          </div>
          <div>Days of the Year ({calculation.daysOfTheYear})</div>
        </div>
        <div className={cls.title}>*</div>
        <div className={cls.title}>Default Days ({calculation.defaultDays})</div>
      </div>

      <div className={cls.row}>
        <div className={cls.titleBold}>Dafault Days ({calculation.defaultDays})</div>
        <div className={cls.title}>=</div>
        <div className={cls.title}>
          <BlueButton outlined={paymentCustomDate === null} onClick={onBtnCustom}>
            Custom Date
          </BlueButton>
          {paymentCustomDate !== null && (
            <div className={cls.line}>
              <InfoTooltip title={`Any date after the Payment (${calculation.paymentDate}) date`} />
              <AntdDateControlledInput
                valueStr={paymentCustomDate}
                onUpdate={setPaymentCustomDate}
                placeholder="DD.MM.YYYY"
                minDate={calculation.paymentAt}
                maxDate={'2050-01-01'}
              />
            </div>
          )}
        </div>
        <div className={cls.title}>
          <BlueButton outlined={paymentCustomDate !== null} onClick={onBtnFull}>
            Up to Date
          </BlueButton>
          <div className={cls.line}>
            <InfoTooltip title={`Payment Date (${calculation.paymentDate}) → Up to Date (${calculation.nowDate}) = ${calculation.daysPaymentToNow} days`} />
          </div>
        </div>
      </div>
    </div>
  );
};

const useStyes = makeStyles({
  box: {
    marginBottom: 30,
  },
  row: {
    marginBottom: 15,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  line: {
    paddingTop: 5,
    display: 'flex',
    position: 'absolute',
  },
  title: {
    color: '#042E6B',
    fontSize: 17,
    margin: '0 7px',
    fontWeight: 500,
  },
  titleBold: {
    color: '#042E6B',
    fontSize: 18,
    fontWeight: 700,
    margin: '0 7px',
  },
  underline: {
    paddingBottom: 2,
    borderBottom: '2px solid #042E6B',
  },
});
