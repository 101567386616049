import { clsx } from 'clsx';
import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import { PlayCircleFilled } from '@ant-design/icons';

import { http } from '@network';
import cls from './file-mp4.module.css';
import { DownloadIcon } from '@components/FileImage';

type Props = {
  url: string;
  previewUrl?: string;
  downloadFilename?: string;
  className?: string;
}

const downloadImage = async (url: string) => {
  const res = await http.get<Buffer>(url, { responseType: 'arraybuffer' });
  return imageBufferToBase64(res.data);
};

const preview = (externalUrl?: string, downloadFilename?: string) => {
  if (!externalUrl) return;

  setTimeout(() => {
    const win = window.open(externalUrl, '_blank') as any;
    win.document.title = downloadFilename || 'Document preview';
  }, 0);
};

export const FileMp4 = ({ url, previewUrl, downloadFilename, className }: Props) => {
  const [src, setSrc] = useState<string>();

  const onPreview = async (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    preview(previewUrl, downloadFilename);
  };

  const onDownload = async (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    let source = src;
    if (!source) {
      source = await downloadImage(url);
      setSrc(source);
    }

    const link = document.createElement('a');

    if (typeof link.download === 'string') {
      link.href = source;
      link.download = downloadFilename || 'Document.mp4';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      preview(previewUrl, downloadFilename);
    }
  };

  return (
    <div className={clsx(cls.box, className, 'file-image-box')} onClick={onPreview} title="preview">
      <div className={clsx(cls.preview, 'file-image-preview')}>
        <PlayCircleFilled className={cls.previewIcon} />
      </div>

      {downloadFilename && (
        <div className={clsx(cls.download, 'file-image-download')}
          title="download" data-x="download">
          <IconButton onClick={onDownload}>
            <DownloadIcon />
          </IconButton>
        </div>
      )}
    </div>
  );
};

function imageBufferToBase64(arrayBuffer: Buffer): string {
  // @ts-ignore
  const b64encoded = btoa([].reduce.call(
    new Uint8Array(arrayBuffer),
    (p: any, c: any) => p + String.fromCharCode(c),
    '',
  ));
  const mimetype = 'image/jpeg';

  return `data:${mimetype};base64,${b64encoded}`;
}
