import cls from './ManualsForm.module.css';

export const validate = (values: any) => {
  const reqList = ['username', 'name', 'email'];
  if (!values.id) {
    reqList.push('password');
    reqList.push('confirmPassword');
  }
  const errors: any = {};
  reqList.forEach((key: string) => {
    if (!values[key]) {
      errors[key] = 'This field is required';
    }
  });
  if (values['confirmPassword'] !== values['password']) {
    errors['confirmPassword'] = 'Passwords are not match';
  }
  if (!values['roleIds']?.length) {
    errors['roleIds'] = 'This field is required';
  }

  return errors;
};

export const ManualsForm = () => {
  return (
    <div className={cls.wrap}>Form</div>
  );
};
