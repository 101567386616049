import { Edit, EditProps, SimpleForm } from 'react-admin';

import { Forbidden, InitialPage } from '@components';
import { useEditProps, useRbacEdit } from '@hooks';
import { ManualsForm, validate } from '@pages/support/manuals/ManualsForm';

const Title = ({ record }: {record?: any}) =>
  record ? (<span>Admin: {record.name}</span>) : null;

export const ManualsEdit = (props: EditProps) => {
  const editProps = useEditProps();
  const rbacEdit = useRbacEdit();

  if (!rbacEdit) {
    return <Forbidden />;
  }

  return (
    <Edit {...props} title={<Title />} {...editProps}>
      <InitialPage
        title="Manual"
        backTo="/manuals"
        backText="Back to other manuals"
      >
        <SimpleForm validate={validate}>
          <ManualsForm />
        </SimpleForm>
      </InitialPage>
    </Edit>
  );
};
