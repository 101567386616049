import { makeStyles } from '@mui/styles';
import { useEffect, useState } from 'react';

import { creditHttp, CreditInterestCalculation } from '@network';
import { BlueButton, InfoTooltip } from '@components';
import { AntdDateControlledInput } from '@components/inputs/AntdDateControlledInput';

interface Props {
  creditId: number;
  interestCustomDate: string | null;
  setInterestCustomDate: (newDate: string | null) => void;
}

export const CreditInterest = ({
  creditId, interestCustomDate, setInterestCustomDate,
}: Props) => {
  const cls = useStyes();
  const [calculation, setCalculation] = useState<CreditInterestCalculation>();

  useEffect(() => {
    (async () => {
      const nextCalculation = await creditHttp.interestCalculation(creditId, {
        interestCustomDate,
      });
      setCalculation(nextCalculation);
    })();
  }, [creditId, interestCustomDate]);

  if (!calculation) {
    return null;
  }

  const onBtnCustom = () => interestCustomDate === null && setInterestCustomDate('');
  const onBtnFull = () => interestCustomDate !== null && setInterestCustomDate(null);

  return (
    <div className={cls.box}>
      <div className={cls.row}>
        <div className={cls.titleBold}>Interests ({calculation.interestMoney})</div>
        <div className={cls.title}>=</div>
        <div className={cls.title}>
          <div className={cls.underline}>Interest Rate ({calculation.interestRate})</div>
          <div>Days of the Year ({calculation.daysOfTheYear})</div>
        </div>
        <div className={cls.title}>*</div>
        <div className={cls.title}>Days of Credit ({calculation.daysOfCredit})</div>
      </div>

      <div className={cls.row}>
        <div className={cls.titleBold}>Days of Credit ({calculation.daysOfCredit})</div>
        <div className={cls.title}>=</div>
        <div className={cls.title}>
          <BlueButton outlined={interestCustomDate === null} onClick={onBtnCustom}>
            Custom Date
          </BlueButton>
          {interestCustomDate !== null && (
            <div className={cls.line}>
              <InfoTooltip title={`Any day between Signature (${calculation.signatureDate}) and Payment (${calculation.paymentDate}) dates`} />
              <AntdDateControlledInput
                valueStr={interestCustomDate}
                onUpdate={setInterestCustomDate}
                placeholder="DD.MM.YYYY"
                minDate={calculation.signatureAt}
                maxDate={calculation.paymentAt}
              />
            </div>
          )}
        </div>
        <div className={cls.title}>
          <BlueButton outlined={interestCustomDate !== null} onClick={onBtnFull}>
            Full Credit Days
          </BlueButton>
          <div className={cls.line}>
            <InfoTooltip title={`Signature (${calculation.signatureDate}) → Payment (${calculation.paymentDate}) dates = ${calculation.daysSignatureToPayment} days`} />
          </div>
        </div>
      </div>
    </div>
  );
};

const useStyes = makeStyles({
  box: {
    marginBottom: 30,
  },
  row: {
    marginBottom: 15,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  line: {
    paddingTop: 5,
    display: 'flex',
    position: 'absolute',
  },
  title: {
    color: '#042E6B',
    fontSize: 17,
    margin: '0 7px',
    fontWeight: 500,
  },
  titleBold: {
    color: '#042E6B',
    fontSize: 18,
    fontWeight: 700,
    margin: '0 7px',
  },
  underline: {
    paddingBottom: 2,
    borderBottom: '2px solid #042E6B',
  },
});
